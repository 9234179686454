import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    lng: 'en-US',
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    // debug: true,
    resources: {
      'en-US': {
        translation: {
          'document-type': 'Document Type',
          'issuer': 'Issuer',
          'identifier': 'Identifier',
          'view-file': 'View',
          'copy-link': 'Copy Link',
          'copied': 'Copied',
          'download-file': 'Download',
          'document-date': 'Document Date',
          'region': 'Region',
          'language': 'Language',
          'description': 'Product Description',
          'download-all':'Download Zip',
          'download-csv':'Download CSV',
          'isin': 'ISIN',
          'ticker': 'Ticker',
          'sedol': 'SEDOL',
          'cusip': 'CUSIP',
          'WTAM': 'WisdomTree Asset Management, Inc.',
          'UCITS': 'WisdomTree Issuer ICAV',
          'WTMA': 'WisdomTree Multi Asset Issuer PLC',
          'FXL': 'WisdomTree Foreign Exchange Limited',
          'CSL': 'WisdomTree Commodity Securities Limited',
          'WIXL': 'WisdomTree Issuer X Limited',
          'HCSL': 'WisdomTree Hedged Commodity Securities Limited',
          'HMSL': 'WisdomTree Hedged Metal Securities Limited',
          'MSL': 'WisdomTree Metal Securities Limited',
          'GBS': 'Gold Bullion Securities Limited',
          'ICBC': 'ICBC Credit Suisse UCITS ETF SICAV',
          'ETP': 'All Issuers',
        },
      },
      'en-GB': {
        translation: {
          'Personal Strategies': 'Personal Strategies',
          'intro-text': 'Analyse and track your favorite portfolios.',
        }
      },
    },
  });

export default i18n;