import React from 'react';
import reactStringReplace from 'react-string-replace';

// Format any date
export function formatDate(dateString) {
    return (
        dateString && (new Date(dateString)).toLocaleDateString(undefined,
            {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }
        )
    );
}

// Replace <strong> and <br/> tags in descriptive text
export function formatText(text) {

    let formattedText = text;
    if (text) {
        formattedText = reactStringReplace(text, '<br/>', () => (<br key={Math.random()} />));
        formattedText = reactStringReplace(formattedText, /<strong>(.*)<\/strong>/g, (match) => (<strong key={Math.random()}>{match}</strong>));
    }

    return formattedText;

}

export function addUrlPart(url, part) {

    if (part) {
        return `${url}${part}/`;
    }

    return url;
}

// Find the unique values of a property in an array of objects
export function uniqueProperties(array, propertyName) {
    return array.map(item => item[propertyName]).filter((value, index, self) => self.indexOf(value) === index);
}
