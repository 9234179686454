import React, { PureComponent } from 'react';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../i18n.js';
import { addUrlPart } from '../../common/index.js';
import './DocumentTable.css';

class DocumentTable extends PureComponent {

    static defaultProps = {
        identifierName: 'identifier',
    };

    constructor(props) {
        super(props);

        this.state = {
            currPage: 0,
            numPerPage: 15,
            urlCopied: null,
        };

        this.paginate = this.paginate.bind(this);
        this.downloadAndShowFile = this.downloadAndShowFile.bind(this);
    }

    paginate({ selected }) {
        this.setState(Object.assign({}, this.state, {
            currPage: selected,
        }));
    }

    // Download the file from the API and show it as a blob in a browser tab - makes 
    // it easier to view
    async downloadAndShowFile(url) {

        const request = {
            method: 'GET',
            url: url,
            responseType: 'blob',
        };

        try {
            // Get the document data from dataspan
            const response = await axios(request);

            // Add on the response to the array
            if (response.data && response.status === 200) {
                // Create a Blob from the PDF Stream
                const file = new Blob([response.data], { type: response.data.type });

                // If a download is needed then download with filename, otherwise
                // just view via blob

                // Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                // Open the URL on new Window
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;

            }
        } catch (e) {
            console.log(e);
        }
        return null;
    };

    renderRows(documents) {
        const {
            baseUrl,
        } = this.props;

        const {
            urlCopied,
        } = this.state;



        return documents.map((doc, index) => {

            let url = baseUrl;
            url = addUrlPart(url, doc.documentType);
            url = addUrlPart(url, doc.issuer);
            url = addUrlPart(url, doc.wtRegion);
            url = addUrlPart(url, doc.isoCode);
            url = addUrlPart(url, doc.identifier);

            const copyLink = urlCopied === url ? <div className='copied'><i>{i18n.t('copied')}</i></div> : 
                <CopyToClipboard
                text={url}
                onCopy={() => this.setState({ urlCopied: url })}>
                    <FontAwesomeIcon icon="copy" size="2x" className='copy-icon' />
                </CopyToClipboard>;

            // Leave off Date for now - will always return the latest in case link is bookmarked
            // url = addUrlPart(url, doc.fileBusinessDate);

            return <tr className={index === 0 ? 'whiteline' : 'greyline'} key={Math.random()}>
                <td className='document-type'>
                    {doc.documentType}
                </td>
                <td className='max-width'>
                    {i18n.t(doc.issuer)}
                </td>
                <td>
                    {doc.wtRegion}
                </td>
                <td>
                    {doc.isoCode}
                </td>
                <td>
                    {doc.identifier}
                </td>
                <td className='description'>
                    {doc.description}
                </td>
                <td>
                    {doc.publishedVersion.fileBusinessDate}
                </td>
                <td
                    className='textCenter view-file'
                    onClick={() => this.downloadAndShowFile(url)}>
                    <FontAwesomeIcon icon="eye" size="2x" />
                </td>
                <td className='textCenter'>
                    <a href={url} target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon="file-download" size="2x" /></a>
                </td>
                <td className='textCenter view-file'>
                   {copyLink}
                </td>

            </tr>;
        });


    }

    render() {

        const {
            identifierName,
            documents,
        } = this.props;

        const {
            currPage,
            numPerPage,
        } = this.state;

        // Set the min to 0 if the filters removed more docs than the page length
        const min = currPage * numPerPage < documents.length ? currPage * numPerPage : 0;
        const max = min + numPerPage;

        const docsToShow = documents.slice(min, max);
        const pageCount = Math.ceil(documents.length / numPerPage);

        return (
            <div className="document-table">
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th className='textLeft sticky'>
                                    {i18n.t('document-type')}
                                </th>
                                <th >
                                    {i18n.t('issuer')}
                                </th>
                                <th >
                                    {i18n.t('region')}
                                </th>
                                <th >
                                    {i18n.t('language')}
                                </th>
                                <th >
                                    {i18n.t(identifierName)}
                                </th>
                                <th >
                                    {i18n.t('description')}
                                </th>
                                <th >
                                    {i18n.t('document-date')}
                                </th>
                                <th >
                                    {i18n.t('view-file')}
                                </th>
                                <th >
                                    {i18n.t('download-file')}
                                </th>
                                <th >
                                    {i18n.t('copy-link')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows(docsToShow)}
                        </tbody>
                    </table>
                </div>

                <div className='row-pagination'>
                    {/* Show pagination */}
                    <ReactPaginate
                        previousLabel={<FontAwesomeIcon icon='chevron-left' size='xs' />}
                        nextLabel={<FontAwesomeIcon icon='chevron-right' size='xs' />}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.paginate}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-item'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'pageLink'}
                        breakLinkClassName={'page-item'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'pageLink'}
                        activeClassName={'active'}
                        disabledClassName={'disabled'}
                    />
                </div>
            </div>
        );
    }

}

export default (DocumentTable);
