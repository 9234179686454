import React, { PureComponent } from 'react';
import Select from 'react-select-v2';
import i18n from '../../i18n.js';
import { uniqueProperties } from '../../common/index.js';
import {
    DOCUMENT_TYPE,
    ISSUER,
    ISO_CODE,
    WT_REGION,
    IDENTIFIER
} from '../App/App.js';
import './Filter.css';

const customStyles = {
    container: (base) => ({
        ...base,
        flex: 1
    }),
    control: (base, state) => ({
        ...base,
        borderRadius: 0,
        ':hover': {
            cursor: 'pointer',
        },
        boxShadow: 'none',
        // borderColor: state.isFocused ? '#D0EAE2' : '#D0EAE2',
    }),
    option: (provided, state) => ({
        ...provided,
        color: '#5b6770',
        fontSize: 14,
        borderRadius: 0,
        ':active': {
            backgroundColor: state.isSelected ? 'white' : '#05A9B1',
            color: state.isSelected ? '#5b6770' : 'white',
        },
        ':hover': {
            backgroundColor: state.isSelected ? 'white' : '#05A9B1',
            color: state.isSelected ? '#5b6770' : 'white',
            cursor: state.isSelected ? 'initial' : 'pointer',
        },
        backgroundColor: state.isDisabled ? '#05A9B1' : 'white',
    }),
    menu: base => ({
        ...base,
        borderRadius: 0
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 100ms';
        return { ...provided, opacity, transition };
    },
    multiValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : .75;
        const fontSize = '14px';
        return { ...provided, opacity, fontSize };
    },
    dropdownIndicator: (base) => ({
        ...base,
        color: '#05A9B1',
    }),

};

class Filter extends PureComponent {

    static defaultProps = {
        identifierName: 'identifier',
    };

    constructor(props) {
        super(props);

        this.state = {
            documentType: null,
            issuer: null,
            wtRegion: null,
            isoCode: null,
            identifier: null,
            fileBusinessDate: null,
        };

        this.handleChangeDocumentType = this.handleChangeDocumentType.bind(this);
        this.handleChangeIssuer = this.handleChangeIssuer.bind(this);
        this.handleChangeWtRegion = this.handleChangeWtRegion.bind(this);
        this.handleChangeIsoCode = this.handleChangeIsoCode.bind(this);
        this.handleChangeIdentifier = this.handleChangeIdentifier.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(filter, selectedOption) {

        this.setState({
            [filter]: selectedOption,
        });
        this.props.updateFilter(filter, selectedOption ? selectedOption.value : null);
    }

    handleChangeDocumentType(selectedOption) {
        this.handleChange(DOCUMENT_TYPE, selectedOption);
    }

    handleChangeIssuer(selectedOptions) {
        this.handleChange(ISSUER, selectedOptions);
    }

    handleChangeWtRegion(selectedOptions) {
        this.handleChange(WT_REGION, selectedOptions);
    }

    handleChangeIsoCode(selectedOptions) {
        this.handleChange(ISO_CODE, selectedOptions);
    }

    handleChangeIdentifier(selectedOptions) {
        this.handleChange(IDENTIFIER, selectedOptions);
    }

    // Map the Issuer filter short definitions to full names
    // This uses i18n to do a lookup in case we need to add any
    arrayToFormOptionsIssuer(values) {
        return values.map(item => ({ value: item, label: i18n.t(item) }));
    }

    arrayToFormOptions(values) {
        return values.map(item => ({ value: item, label: item }));
    }

    valueToFormValue(value) {
        return ({
            'value': value,
            'label': value,
        });
    }

    render() {

        const {
            documents,
            docsToShow,
            identifierName,
        } = this.props;

        const {
            documentType,
            issuer,
            wtRegion,
            isoCode,
            identifier,
        } = this.state;

        // documentType uses all doc types every time. The other filters are
        // dynamic based on what's been filtered
        const allDocumentTypes = uniqueProperties(documents, DOCUMENT_TYPE);
        const allIssuers = uniqueProperties(docsToShow, ISSUER);
        const allWtRegions = uniqueProperties(docsToShow, WT_REGION);
        const allIsoCodes = uniqueProperties(docsToShow, ISO_CODE);
        const allIdentifiers = uniqueProperties(docsToShow, IDENTIFIER);

        return (
            <div className='filters'>
                <Select
                    placeholder={i18n.t('document-type')}
                    styles={customStyles}
                    isMulti={false}
                    onChange={this.handleChangeDocumentType}
                    options={this.arrayToFormOptions(allDocumentTypes)}
                    value={documentType}
                    closeMenuOnSelect={true}
                    isClearable
                    className='document-type'
                />
                <Select
                    placeholder={i18n.t('issuer')}
                    styles={customStyles}
                    isMulti={false}
                    onChange={this.handleChangeIssuer}
                    options={this.arrayToFormOptionsIssuer(allIssuers)}
                    value={issuer}
                    closeMenuOnSelect={true}
                    isClearable
                    className='issuer'
                />
                <Select
                    placeholder={i18n.t('region')}
                    styles={customStyles}
                    isMulti={false}
                    onChange={this.handleChangeWtRegion}
                    options={this.arrayToFormOptions(allWtRegions)}
                    value={wtRegion}
                    closeMenuOnSelect={true}
                    isClearable
                    className='wt-region'
                />
                <Select
                    placeholder={i18n.t('language')}
                    styles={customStyles}
                    isMulti={false}
                    onChange={this.handleChangeIsoCode}
                    options={this.arrayToFormOptions(allIsoCodes)}
                    value={isoCode}
                    closeMenuOnSelect={true}
                    isClearable
                    className='iso-code'
                />
                <Select
                    placeholder={i18n.t(identifierName)}
                    styles={customStyles}
                    isMulti={false}
                    onChange={this.handleChangeIdentifier}
                    options={this.arrayToFormOptions(allIdentifiers)}
                    value={identifier}
                    closeMenuOnSelect={true}
                    isClearable
                    className='identifier'
                />
            </div>
        );
    }

}

export default (Filter);
