import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './components/App/App';
import './index.css';
import i18n from './i18n';

// App script in page will provide the id to mount.
// Taken from https://stackoverflow.com/questions/53562804/how-to-include-the-same-react-app-twice-on-the-same-page
window.mount = function (id) {

  const element = document.getElementById(id);

  const windowAppId = `${process.env.REACT_APP_WINDOW_APP_ID}` ? `${process.env.REACT_APP_WINDOW_APP_ID}` : 'portfolioMapper';
  const initParams = {};

  initParams.wtRegion = window[windowAppId] && `${window[windowAppId].wtRegion}` && `${window[windowAppId].wtRegion}` !== 'undefined' ? `${window[windowAppId].wtRegion}` : "US";
  initParams.entityCCY = window[windowAppId] && `${window[windowAppId].entityCCY}` && `${window[windowAppId].entityCCY}` !== 'undefined' ? `${window[windowAppId].entityCCY}` : "USD";
  initParams.tenant = window[windowAppId] && `${window[windowAppId].tenant}` && `${window[windowAppId].tenant}` !== 'undefined' ? `${window[windowAppId].tenant}` : "wisdomtree";
  initParams.token = window[windowAppId] && `${window[windowAppId].token}` && `${window[windowAppId].token}` !== 'undefined' ? `${window[windowAppId].token}` : "";
  initParams.clientEmail = window[windowAppId] && `${window[windowAppId].clientEmail}` && `${window[windowAppId].clientEmail}` !== 'undefined' ? `${window[windowAppId].clientEmail}` : "unknown@unknown.com";
  initParams.dataspanApiUrl = `${process.env.REACT_APP_API_URL}`;
  initParams.dpdApiUrl = `${process.env.REACT_APP_DPD_URL}`;
  initParams.version = `${process.env.REACT_APP_VERSION}` !== 'undefined' ? `${process.env.REACT_APP_VERSION}` : 'DEV';
  initParams.isoCode = window[windowAppId] && `${window[windowAppId].isoCode}` && `${window[windowAppId].isoCode}` !== 'undefined' ? `${window[windowAppId].isoCode}` : "en-US";


  // Change the language to that specified in the page.
  i18n.changeLanguage(initParams.isoCode);

  // Get the div data params
  initParams.documentsToLoad = element.dataset.documentstoload ? JSON.parse(element.dataset.documentstoload) : null;
  initParams.identifierName = element.dataset.identifiername ? element.dataset.identifiername : 'identifier';

  // render
  ReactDOM.render((

    <BrowserRouter>
     <App params={initParams} />
    </BrowserRouter>

  ),
    element);
}
